exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-basic-setup-for-a-web-application-basic-setup-for-a-web-application-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/basic-setup-for-a-web-application/basic-setup-for-a-web-application.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-basic-setup-for-a-web-application-basic-setup-for-a-web-application-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-benefits-of-css-in-js-benefits-of-css-in-js-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/benefits-of-css-in-js/benefits-of-css-in-js.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-benefits-of-css-in-js-benefits-of-css-in-js-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-form-building-with-react-hook-form-form-building-with-react-hook-form-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/form-building-with-react-hook-form/form-building-with-react-hook-form.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-form-building-with-react-hook-form-form-building-with-react-hook-form-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-setup-react-with-webpack-setup-react-with-webpack-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/setup-react-with-webpack/setup-react-with-webpack.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-setup-react-with-webpack-setup-react-with-webpack-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-tailwind-favourite-way-to-style-tailwind-favourite-way-to-style-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/content/posts/tailwind-favourite-way-to-style/tailwind-favourite-way-to-style.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-content-posts-tailwind-favourite-way-to-style-tailwind-favourite-way-to-style-mdx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

